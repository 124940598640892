@use '~@moda/om';

.DesktopSiteNavItem {
  $self: &;

  display: flex;

  &__link {
    @include om.text(eyebrow);

    color: om.color(ink);
    padding: om.space(4);
    text-decoration: none;
    white-space: nowrap;

    &--club {
      color: om.color('snow');
    }

    @include om.breakpoint(lg, $prop: 'max-width') {
      padding-left: om.space(3);
      padding-right: om.space(3);
    }
  }

  &__pulldown {
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    z-index: 1;
  }

  &--hidden {
    visibility: hidden;
  }
}
