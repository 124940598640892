@use '~@moda/om';

.DesktopSiteNavActions {
  @include om.stack(4, $direction: horizontal);

  & {
    align-items: center;
    justify-content: flex-end;
  }

  &__favorite,
  &__search {
    color: om.color(ink);
  }

  &__favorite {
    cursor: pointer;
    text-decoration: none;
  }
}
