@use '~@moda/om';

.SiteFooterLargeDesktop {
  &__content {
    column-gap: om.space(8);
    display: grid;
    grid-template-columns: 60% auto;
    padding-bottom: om.space(3);
    position: relative;
  }

  &__divider {
    &::before,
    &::after {
      background-color: om.color('chatelle');
      height: 0.5px;
    }
  }
}
