@use '~@moda/om';

.MobileNavMenu {
  display: flex;
  position: absolute;
  inset: 0;
  transform: translateX(-100%);
  transition: transform 500ms;

  &__content-wrapper {
    width: 68%;
  }

  &--active {
    transform: translate(0);
  }

  &__close {
    width: 32%;
  }
}
