@use '~@moda/om';

/* stylelint-disable */

@keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.DesktopSiteNavItemLoading {
  @include om.text(eyebrow);

  display: flex;
  justify-content: space-around;
  padding: om.space(4);
  user-select: none;
  width: 75%;

  > span {
    color: om.color(elephant);
    background-color: om.color(elephant);
    animation: pulse 2s infinite;
  }
}
