@use '~@moda/om';

.DesktopNavigationListColumn {
  &__list {
    margin: om.spacing(3, 0, 4, 0);
  }

  &__header,
  &__header-secondary {
    @include om.text(eyebrow);

    margin: om.spacing(0, 2, 1, 2);
    font-weight: 700;

    // Editors sometimes allow headlines to overflow
    // into neighboring cells where there might not be text

    white-space: nowrap;
  }

  &__header-secondary {
    margin: om.spacing(4, 2, 1, 2);
  }

  &__divider {
    width: 25%;
    height: 1px;
    margin: om.spacing(3, 2);
    border: 0;
    background-color: om.color(elephant);
  }

  &__link {
    @include om.text(body1);

    display: block;
    text-decoration: none;
    color: om.color(ink);
    padding: om.spacing(1, 2);

    &:hover {
      background-color: om.color(noise);
    }
  }
}
