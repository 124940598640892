@use '~@moda/om';

.DesktopSiteNavImageColumn {
  &__anchor {
    display: block;
    text-decoration: none;
    color: om.color(ink);
  }

  &__header {
    @include om.text(eyebrow);

    margin: om.spacing(3, 0);

    // Editors sometimes allow headlines to overflow
    // into neighboring cells where there might not be text

    white-space: nowrap;
  }

  &__sub-header {
    @include om.text(h6, $font: serif);

    margin: om.spacing(3, 2, 1, 2);
    text-align: center;
  }

  &__sub-sub-header {
    @include om.text(body1, $font: serif);

    text-align: center;
  }
}
