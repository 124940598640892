@use '~@moda/om';

.SiteFooterMailingList {
  @include om.stack(2);

  &__options {
    @include om.stack(2, $direction: horizontal);

    & {
      align-items: center;
    }
  }

  &__input {
    position: relative;
  }

  &__button {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    padding: om.spacing(0, 3);
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: om.color('snow');
    background: om.color(ink);
    text-transform: uppercase;
  }

  &__opt-out {
    @include om.text(body2);
  }

  &__status {
    @include om.text(body1);

    &--error {
      color: om.color(code-red);
    }

    &--success {
      color: om.color(money-good);
    }
  }

  &__confirmation {
    display: flex;
    margin-top: om.space(4);
  }

  &__confirmationtext {
    margin-left: 1rem;
    flex: 1;
  }

  &__manage-subscriptions-link {
    color: om.color(money-good);
    text-decoration: underline;
  }

  &__opt-out-link {
    color: om.color(ink);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &__input-button {
    width: calc(100% - 44px);
  }

  &__title {
    font-weight: 700;
  }
}
