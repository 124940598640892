@use '~@moda/om';

.DesktopNavigationMainContentCategory {
  text-decoration: none;
  position: relative;

  &--selected::after,
  &--unselected::after {
    position: absolute;
    bottom: -0.8rem;
    display: block;
    content: ' ';
    height: 0.185rem;
    width: 100%;
    background-color: om.color(klein-blue);
  }

  &--unselected::after {
    opacity: 0;
  }

  &:hover::after {
    opacity: 1;
    transition: opacity 1s;
  }
}
