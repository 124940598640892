.MobileNavEditorialsSection {
  overflow-x: scroll;
  display: flex;
  margin-right: -(space(4));

  &::-webkit-scrollbar {
    display: none;
  }

  &__image {
    width: auto;
    max-width: initial;
    height: 16rem;
  }

  &__placeholder {
    height: 1.3rem;
  }
}
