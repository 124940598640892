@use '~@moda/om';

.DesktopSiteNavPulldown {
  $column-width: 11rem;

  display: flex;
  justify-content: center;
  padding: om.spacing(4, 4, 7, 4);
  color: om.color(ink);
  background-color: om.color('snow');
  border-bottom: 1px solid om.color(noise);
  box-shadow: 0 om.space(4) om.space(4) -1rem om.color(ink, 0.2);

  &__column {
    width: $column-width;
    padding-right: om.space(4);
  }
}
