@use '~@moda/om';
@use '../../styles/variables';

.EmailSignup {
  position: fixed;
  z-index: variables.z('modal-overlay');

  &__wrapper {
    position: relative;
    background-color: om.color(cream);
    text-align: center;
    width: 100%;
    max-width: 45rem;
    display: flex;

    @include om.breakpoint(sm, $prop: max-width) {
      max-width: 90vw;
    }
  }

  &__container {
    flex: 1;

    @include om.breakpoint(sm, $prop: max-width) {
      flex: 2;
    }
  }

  &__right {
    height: 100%;

    @include om.breakpoint(sm, $prop: max-width) {
      padding: om.spacing(4, 2, 4, 4);
    }
  }

  &__content {
    height: 30rem;
    align-items: center;
    padding: 0 2.5rem 1.5rem;

    @include om.breakpoint(sm, $prop: max-width) {
      align-items: flex-start;
      height: auto;
      padding: 0;
    }
  }

  &__content--center {
    @include om.breakpoint(sm, $prop: max-width) {
      align-items: center;
    }
  }

  &__image-wrapper {
    display: flex;
    flex: 1;
  }

  &__image-wrapper--hide {
    @include om.breakpoint(sm, $prop: max-width) {
      display: none;
    }
  }

  &__image {
    object-fit: contain;
  }

  &__submit {
    @include om.breakpoint(sm, $prop: max-width) {
      display: none;
    }
  }

  &__title {
    padding-top: om.space(10);

    @include om.breakpoint(sm, $prop: max-width) {
      padding-top: 0;
    }
  }

  &__confirmation {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: om.space(9);
    padding-bottom: om.space(9);

    @include om.breakpoint(sm, $prop: max-width) {
      padding: 0;
    }
  }

  &__finalstep {
    margin-bottom: om.space(4);
  }

  &__terms {
    padding-top: om.space(7);

    @include om.breakpoint(sm, $prop: max-width) {
      padding: 0;
      text-align: center;
    }
  }

  &__termsconditions {
    text-align: left;
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    padding: om.spacing(4);
    cursor: pointer;
    color: om.color(ink);

    &:hover {
      color: om.color(cement);
    }
  }

  &__logo {
    width: 100%;
    height: om.space(4);
    margin-top: 2.5rem;

    @include om.breakpoint(sm, $prop: max-width) {
      display: none;
    }
  }

  &__modalogo {
    margin: auto;
  }

  &__homelink {
    color: om.color(ink);
  }

  &__discount {
    font-weight: bold;
  }

  &__dismiss {
    text-decoration: underline;
    padding-top: om.space(4);

    @include om.breakpoint(sm, $prop: max-width) {
      display: none;
    }
  }

  &__inputform {
    position: relative;

    @include om.breakpoint(sm, $prop: max-width) {
      width: 95%;
    }
  }

  &__submitbutton {
    display: none;

    @include om.breakpoint(sm, $prop: max-width) {
      display: flex;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      padding: om.spacing(0, 3);
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: om.color('snow');
      background: om.color(ink);
      text-transform: uppercase;
    }
  }

  &__bodycontainer {
    @include om.breakpoint(sm, $prop: max-width) {
      // stylelint-disable-next-line declaration-no-important
      margin: 0 !important;
    }
  }

  &__heading {
    @include om.breakpoint(sm, $prop: max-width) {
      // stylelint-disable-next-line declaration-no-important
      margin: 0 !important;
    }
  }

  &__body {
    width: 100%;
    align-items: center;

    @include om.breakpoint(sm, $prop: max-width) {
      align-items: flex-start;
      text-align: left;
    }
  }

  &__form {
    @include om.stack(3);

    & {
      text-align: left;
      width: 100%;
    }
  }

  &__footer {
    text-align: center;
    width: 95%;
    margin-top: om.space(6);

    @include om.breakpoint(sm, $prop: max-width) {
      width: 100%;
    }
  }

  &__status {
    @include om.text(body1);

    &--error {
      color: om.color(code-red);
    }
  }

  &__link {
    color: om.color(cement);
  }

  @include om.breakpoint(sm, $prop: max-width) {
    // stylelint-disable-next-line plugin/selector-bem-pattern
    .TextInput {
      width: calc(100% - 44px);
    }
  }
}
