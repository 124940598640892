@use '~@moda/om';

.MobileNavSubcategoriesSection {
  margin-top: om.space(3);

  &__header {
    text-align: center;
    padding: om.space(3);
  }
}
