@use '~@moda/om';
@use '../../../styles/variables';

.MobileNavOverlay {
  position: fixed;
  inset: 0;
  transition-property: background-color, top;
  transition-duration: 200ms;
  z-index: variables.z(mobile-site-nav-overlay);

  &--active {
    background-color: om.color('snow', 0.71);
  }
}
