@use '~@moda/om';

.MobileNavTab {
  background-color: om.color('snow');
  padding: om.space(5) om.space(4);
  height: 100%;

  &__category {
    width: 100%;
  }

  &__no-header-subcategories {
    padding-left: 0;
  }
}
