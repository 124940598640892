/* stylelint-disable */
@use '~@moda/om';
@use '~@moda/om/dist/styles';
@use 'styles/variables';

@include om.global-styles();

.Overlay {
  z-index: variables.z(overlay);
}

.ModalOverlay {
  z-index: variables.z(modal-overlay);
}

.SlidingPane {
  z-index: variables.z(modal-overlay);

  &__overlay {
    z-index: variables.z(modal-overlay);
  }
}

.evidon-banner {
  z-index: variables.z(evidon-banner) !important;
}
