@use '~@moda/om';

.QuickSearchResults {
  $self: &;
  $result-padding: om.spacing(1, 4);
  $result-padding-mobile: om.spacing(3, 5);

  @include om.stack(4);

  & {
    padding: om.spacing(2, 0);

    // TODO: Extract into mixin `overflow-scrolling`
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  // Indicators
  &__error,
  &__loading,
  &__none {
    @include om.text(body2);

    padding: $result-padding;
  }

  &__error {
    color: om.color(code-red);
  }

  &__loading,
  &__none {
    color: om.color(cement);
  }

  &__header,
  &__link {
    padding: $result-padding;
  }

  &__header {
    @include om.text(eyebrow);

    color: om.color(cement);
  }

  &__link {
    @include om.text(body1);

    display: block;
    color: om.color(ink);
    text-decoration: none;

    &:hover,
    &--active {
      background-color: om.color(noise);
      text-decoration: underline;
    }
  }

  &--mobile {
    @include om.stack(0);

    & {
      padding: 0;
    }

    // stylelint-disable-next-line
    #{$self}__error,
    #{$self}__loading,
    #{$self}__none {
      @include om.text(body1);

      padding: $result-padding-mobile;
    }

    // stylelint-disable-next-line
    #{$self}__header {
      @include om.text(body1);

      text-transform: unset;
      padding: $result-padding-mobile;
      color: om.color(ink);
      background-color: om.color(noise);
    }

    // stylelint-disable-next-line
    #{$self}__link {
      @include om.text(body1);

      padding: $result-padding-mobile;
      color: om.color(ink);
      border-bottom: 1px solid om.color(noise);
    }
  }
}
