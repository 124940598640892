@use '~@moda/om';

.MobileNavContent {
  height: 100%;
  background-color: om.color('snow');
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &__user-actions {
    padding-top: om.space(4);
    padding-bottom: om.space(4);
  }
}
