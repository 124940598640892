@use '~@moda/om';

.SiteFooterMobileMailingList {
  &__title {
    font-weight: 700;
  }

  &__input {
    position: relative;
    margin: om.spacing(4, 0);
  }

  &__section-title {
    display: block;
    font-weight: 700;
  }

  &__button {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    padding: om.spacing(0, 3);
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: om.color('snow');
    background: om.color(ink);
    text-transform: uppercase;
  }

  &__confirmationtext {
    margin: om.spacing(2, 0);
    display: block;
  }

  &__status {
    @include om.text(body1);

    &--error {
      color: om.color(code-red);
    }

    &--success {
      color: om.color(money-good);
    }
  }

  &__manage-subscriptions-link {
    color: om.color(money-good);
    text-decoration: underline;
  }

  &__opt-out-link {
    color: om.color(ink);
    text-decoration: none;
  }

  &__input-button {
    width: calc(100% - 44px);
  }
}
