/* stylelint-disable */

.grecaptcha-badge {
  display: none;
}

.recaptcha-challenge-container {
  position: fixed !important;
  top: 10px !important;
}
