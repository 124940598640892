@use '~@moda/om';

.MiniCartLink {
  @include om.stack(0, $direction: horizontal);

  & {
    position: relative;
    align-items: center;
    color: om.color(ink);
    cursor: pointer;
    text-decoration: none;
  }

  &--with-count {
    @include om.breakpoint(md, $prop: max-width) {
      padding-right: om.space(2);
    }
  }

  &__count {
    line-height: om.space(5);

    @include om.breakpoint(md, $prop: max-width) {
      position: absolute;
      top: 100%;
      left: 100%;
      margin-left: -#{om.space(2)};
      transform: translate(-50%, -60%);
      background-color: om.color('snow');
      line-height: 1;
    }
  }
}
