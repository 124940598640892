@use '~@moda/om';

.AccountLinks {
  padding: om.spacing(0, 4);
  width: 10rem;
  text-align: left;

  &__link {
    display: block;
    color: om.color(ink);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    &:first-child {
      padding-top: om.spacing(4);
    }

    &:last-child {
      padding: om.spacing(4, 0);
      border-top: 1px solid om.color(elephant);
    }
  }
}
