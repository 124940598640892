@use '~@moda/om';

.DesktopNavigationVertical {
  text-decoration: none;

  @mixin fake-item-bottom-border {
    background-color: om.color(klein-blue);
    content: ' ';
    display: block;
    height: 0.185rem;
    margin-top: om.space(1);
    width: 100%;
  }

  &--selected::after {
    @include fake-item-bottom-border;
  }

  &--unselected::after {
    @include fake-item-bottom-border;

    opacity: 0;
  }

  &:hover::after {
    opacity: 1;
    transition: opacity 0.5s;
  }

  &__title {
    text-transform: uppercase;
  }
}
