@use '~@moda/om';

.SitePromoBanner {
  $self: &;

  & {
    border-top: none;
    background-color: om.color('lilac');
    padding: 0.125rem 0;
  }

  &--club {
    border-top: 0.25rem solid om.color('brick');
    background-color: om.color('ivory');
  }
  
  @include om.breakpoint(sm, $prop: max-width) {
    padding-bottom: om.space(1);
  }

  &__cta {
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: om.color('persian-blue');
    display: block;
  }

  &__cta--club,
  &__country-selector--club {
    color: om.color('persian-blue');
  }

  // stylelint-disable-next-line plugin/selector-bem-pattern
  .swiper-slide-active {
    z-index: 1;
  }

  // stylelint-disable-next-line plugin/selector-bem-pattern, selector-max-compound-selectors
  .PromoBanner__child > a::after {
    border-bottom: none;
  }

  &__country-selector {
    margin-bottom: -0.65rem;
    height: om.space(5);
    color: om.color('persian-blue');
  }
}
