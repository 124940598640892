@use '~@moda/om';

.CountrySelector {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__input {
    flex: 1;

    // stylelint-disable-next-line
    input {
      width: 100%;
      border: 0;

      // stylelint-disable-next-line
      &,
      &:focus {
        border-bottom: 1px solid om.color(elephant);
      }
    }
  }

  &__list {
    padding: om.spacing(1, 0);

    // TODO: Extract into mixin `overflow-scrolling`
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  &__empty {
    @include om.text(body1);

    padding: om.spacing(1, 4);
    color: om.color(elephant);
  }
}
