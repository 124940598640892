@use '~@moda/om';

.Crossfader {
  $self: &;

  position: relative;

  &--transitioning {
    // stylelint-disable-next-line plugin/selector-bem-pattern
    #{$self}__current {
      opacity: 0;
    }

    // stylelint-disable-next-line plugin/selector-bem-pattern
    #{$self}__next {
      opacity: 1;
    }
  }

  &__current {
    position: relative;
    opacity: 1;
  }

  &__next {
    position: absolute;
    inset: 0;
    opacity: 0;
  }
}
