@use '~@moda/om';

/* stylelint-disable */

.SiteFooterInlineItem {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding-right: om.space(3);
  padding-left: om.space(2);

  &::after {
    display: block;
    content: '';
    position: absolute;
    top: 3px;
    bottom: 0;
    right: 0;
    border-right: 1px solid om.color('elephant');
    height: 70%;

    @include om.breakpoint(md, $prop: min-width) {
      border-right: 1px solid om.color('persian-blue');
    }
  }

  &__legal::after,
  &__copyright::after {
    display: none;
  }

  &__legal,
  &__copyright {
    padding-right: 0;
  }

  &__app {
    padding-left: 0;
  }

  > a {
    color: om.color(ink);
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    @include om.breakpoint(md, $prop: min-width) {
      color: om.color('persian-blue');
    }
  }
}
