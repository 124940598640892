@use '~@moda/om';

.DesktopNavigationEditorialColumn {
  &__anchor {
    display: block;
    text-decoration: none;
    color: om.color(ink);
  }

  &__header {
    margin-bottom: om.space(3);
  }

  &__header-placeholder {
    height: 1.3rem;
  }

  &__image {
    margin-top: om.space(2);
  }
}
