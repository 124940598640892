@use '~@moda/om';

.MobileNav {
  border-bottom: 1px solid om.color(elephant);
  padding: om.spacing(4, 3);

  @media (width >= 350px) {
    padding: om.spacing(4, 4);
  }

  &__rail {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__hamburger,
  &__logo,
  &__actions {
    color: om.color(ink);
  }

  &__logo--club {
    color: om.color(persian-blue);
  }

  &__bag,
  &__favorite {
    cursor: pointer;
    color: om.color(ink);
  }

  &__actions {
    @include om.stack(2, $direction: horizontal);

    @media (width >= 350px) {
      @include om.stack(4, $direction: horizontal);
    }

    & {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__search {
    width: 100%;
    margin: om.spacing(2, 0, 0);
    transition:
      display 0s,
      opacity 0.5s linear;
    // stylelint-disable-next-line plugin/selector-bem-pattern
    input {
      background-color: om.color(noise);
    }
  }
}
