@use '~@moda/om';

.CountrySelectorItem {
  $self: &;

  @include om.text(body1);

  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: om.spacing(1, 4);
  cursor: pointer;

  &__name {
    color: om.color(ink);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: om.space(4);
  }

  &__currency {
    color: om.color(fog);
  }

  &--active,
  &:hover {
    // stylelint-disable-next-line
    #{$self}__name {
      text-decoration: underline;
    }
  }
}
