@use '~@moda/om';
@use '../../styles/variables';

.MinimalNav {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: om.color('snow');
  border-bottom: 1px solid om.color(elephant);
  z-index: variables.z(site-nav);

  @include om.breakpoint(md, $prop: max-width) {
    padding: om.spacing(4, 0);

    &__logo {
      width: om.space(11);
      height: om.space(3);
    }
  }

  @include om.breakpoint(md) {
    &__logo {
      width: 18rem;
      height: 5rem;
    }
  }

  &__link {
    color: om.color(ink);
  }
}
