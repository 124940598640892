@use '~@moda/om';

.SiteFooterDesktop {
  &__mailing-list {
    align-self: center;
    width: 65%;
  }

  &__divider {
    &::before,
    &::after {
      background-color: om.color('chatelle');
      height: 0.5px;
    }
  }

  &__icon {
    color: om.color('persian-blue');
    margin-right: om.space(2);
  }

  &__icon:last-child {
    margin-right: 0;
  }
}
