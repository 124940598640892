@use '~@moda/om';

.DesktopNavigationPulldown {
  $column-width: 11rem;

  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
  padding: om.spacing(6, 4, 7, 4);
  color: om.color(ink);
  background-color: om.color('snow');
  border-bottom: 1px solid om.color(noise);
  box-shadow: 0 om.space(4) om.space(4) -1rem om.color(ink, 0.2);
  animation: smooth-render 1s;

  @keyframes smooth-render {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  &__column {
    width: $column-width;
    padding-right: om.space(4);
  }
}
