@use '~@moda/om';
@use '../../styles/variables';

.QuickSearch {
  $self: &;

  position: relative;

  &__results {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin-top: -1px;
    max-height: 86vh;
    overflow-y: auto;
    background-color: om.color('snow');
    border: 1px solid om.color(ink);
    z-index: variables.z(quick-search-results);
  }

  &--mobile {
    // stylelint-disable-next-line
    #{$self}__results {
      margin-top: -(om.space(2));
      margin-right: -(om.space(2));
      margin-left: -(om.space(2));
      border: 0;
      overflow-y: auto;
    }
  }
}
