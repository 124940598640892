@use '~@moda/om';

.DesktopNavigationMainContent {
  &__items {
    padding-bottom: om.space(3);
  }

  &__divider {
    &::before,
    &::after {
      background-color: om.color('chatelle');
    }
  }
}
