@use '~@moda/om';

.ResponsiveImage {
  background-color: om.color(noise);

  &__image {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}
