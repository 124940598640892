@use '~@moda/om';
@use 'SiteFooterInlineItem';

.SiteFooter {
  $border-width: om.space(1);

  // Double border
  border-top: $border-width solid om.color(lilac);
  box-shadow: 0 (-$border-width) 0 om.color(burnt-orange);
  margin-top: $border-width;

  @include om.text(body1);

  padding: om.spacing(3, 0, 5);
  color: om.color(ink);
  background-color: om.color(cream);

  @include om.breakpoint(md) {
    padding: om.spacing(6, 0, 5, 0);
  }

  @include om.breakpoint(md, $prop: min-width) {
    box-shadow: none;
    border-top: 1px solid #d5c2d8;
    padding: om.spacing(6, 0, 6);
    background-color: om.color('snow');
  }
}
