@use '~@moda/om';
@use '../../styles/variables';

.EmailVerificationStatusModal {
  max-width: 90vw;
  background-color: om.color('snow');
  text-align: center;

  &__header {
    padding: om.spacing(4, 6);
    border-bottom: 1px solid om.color('elephant');
  }

  &__content {
    text-align: center;
  }

  &__sliding-pane {
    z-index: #{z(evidon-banner) + 1};
  }

  @include om.breakpoint(sm) {
    &__content {
      padding: om.spacing(6);
    }
  }

  @include om.breakpoint(md) {
    max-width: 40rem;
  }
}
