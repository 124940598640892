@use '../../../styles/variables';
@use '~@moda/om';

.MobileNavHeader {
  position: sticky;
  top: 0;
  z-index: variables.z(one);

  &__tab {
    background-color: om.color(noise);
    padding: om.space(3) om.space(2);
    text-align: center;
  }

  &__tab--selected {
    background-color: om.color('snow');
  }

  &__tab--two-items {
    width: 50%;
  }

  &__tab--three-items {
    width: calc(100% / 3);
  }

  &__tab--four-items {
    width: 25%;
  }
}
