@use '~@moda/om';

.SitePromoBannerLink {
  @include om.text(body2);

  color: om.color('persian-blue');
  cursor: pointer;
  text-decoration: none;

  &__account-icon {
    color: om.color('ink');
  }

  &__popover {
    display: inline-block;
  }

  &--club {
    color: om.color('persian-blue');
  }

  &:hover {
    text-decoration: none;
  }

  &__greetings-top {
    font-size: 0.7rem;
    line-height: 0.7rem;
  }

  &__left {
    height: 100%;
  }
}

.SitePromoBannerLink__welcoming-message {
  display: flex;
  flex-direction: column;

  @include om.breakpoint(lg, $prop: max-width) {
    @include om.breakpoint(md) {
      display: none;
    }
  }
}
