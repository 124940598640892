@use '~@moda/om';

.MobileSiteNavMenuPaneBack {
  @include om.text(h6, $font: serif);

  display: flex;
  width: 100%;
  padding: om.spacing(3, 4);
  text-align: center;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  border-bottom: 1px solid om.color(noise);

  &__name {
    flex: 1;
    margin-right: om.space(4);
  }

  &:focus {
    outline: 0;
    text-decoration: underline;
  }
}
