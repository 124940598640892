@use '~@moda/om';

/* stylelint-disable */

.SiteFooterBasement {
  @include om.text(body2);

  color: om.color('persian-blue');

  &__left > a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &__shapes {
    align-self: flex-end;
  }
}
