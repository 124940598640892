@use '~@moda/om';

.DesktopSiteNav {
  $self: &;
  $logo-width: 18rem;
  $logo-height: 5rem;

  &__primary {
    display: flex;
    flex-direction: row;
    align-items: center;

    // stylelint-disable-next-line plugin/selector-bem-pattern
    > * {
      flex: 1;
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;

    // stylelint-disable-next-line plugin/selector-bem-pattern
    > a {
      width: $logo-width;
      height: $logo-height;
      color: om.color(ink);
    }
  }

  &__nav-items {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    background-color: om.color(noise);
  }

  &__nav-items--club {
    background-color: om.color('brick');
  }

  &__nav-item {
    height: om.space(7);

    &:hover {
      border-bottom: 2px solid om.color(ink);
    }
  }

  &__logo-icon--club {
    color: om.color(persian-blue);
  }
}
