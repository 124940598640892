@use '~@moda/om';

/* stylelint-disable */

.MobileSiteNavOption {
  @include om.text(body1);

  display: flex;
  align-items: center;
  width: 100%;
  padding: om.spacing(2, 4);
  color: om.color(ink);
  text-decoration: none;
  cursor: pointer;

  &__label {
    display: flex;
    align-items: center;
    flex: 1;

    // Insert space before icon if present
    > * {
      margin-left: om.space(2);
    }
  }

  &[title='Sale'],
  &[title='SALE'] {
    color: om.color('code-red');
    font-weight: bold;
  }
}
