@use '~@moda/om';

.SiteFooterBasementCheckoutMobile {
  @include om.text(body1);

  color: om.color(ink);

  &__download {
    padding-top: om.space(6);
    justify-content: center;
    text-decoration: none;
  }
}
