@use '~@moda/om';

.ErrorFallback {
  // TODO: Extract to tokens
  $code-font-family: menlo, monaco, consolas, 'Courier New', monospace;
  $code-font-size: 0.75rem;
  $code-line-height: 1.5;

  margin: om.spacing(6, 0);

  &__header {
    @include om.text(h4);

    margin: 0;
    color: om.color(ink);
  }

  &__message {
    @include om.text(h5);

    margin-bottom: om.space(6);
    color: om.color(cement);
  }

  &__stack {
    position: relative;
    color: om.color(code-red);
    background-color: om.color(strawberry);
    border-radius: 0.25em;
    overflow-y: auto;
    padding: om.spacing(3, 4);
    font-family: $code-font-family;
    font-size: $code-font-size;
    line-height: $code-line-height;
  }
}
