@use '~@moda/om';

.Dialog {
  max-width: 90vw;
  background-color: om.color('snow');
  text-align: center;

  &__content {
    padding: om.spacing(6);
  }

  @include om.breakpoint(md) {
    max-width: 40rem;
  }
}
