@use '~@moda/om';

.PageLayout {
  display: flex;
  min-height: 100vh;
  flex-direction: column;

  &__content {
    display: flex;
    flex-direction: column;
    position: relative;
    flex: 1;
    min-height: om.space(10);
  }
}
