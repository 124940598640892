@use '~@moda/om';

.SiteFooterMobileBasement {
  &__item {
    display: inline-flex;
    margin-right: om.space(4);
    margin-bottom: om.space(4);
  }

  &__copyright {
    display: block;
  }

  &__shapes {
    display: block;
    margin: om.space(4) auto 0;
  }
}
